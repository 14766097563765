<template>
  <div class="flex items-center progress">
    <div class="w-11/12 h-full border-b">
      <div class="flex justify-between items-center" style="height:50px;">
        <div>
          <p>{{ title }}</p>
          <p v-if="subTitle" class="text-xs text-gray-500">{{ subTitle }}</p>
        </div>
        <div>
          <div class="text-2xl text-right" v-tooltip="getCountTitle">
            {{ count }}
          </div>
          <p
            v-if="
              type === 'iot-unlock' ||
                type === 'iot-unlock-reason' ||
                type === 'iot-lock' ||
                type === 'iot-lock-reason'
            "
            class="text-xs text-gray-500"
            v-tooltip="getTotalRatePeriodPercentageTitle"
          >
            {{ value }}%
          </p>
        </div>
      </div>
      <progress-bar-x
        :value="value"
        :animated="true"
        :height="5"
        :radius="60"
      />
    </div>
    <div class="flex justify-end items-center pt-2 w-1/6 h-full  md:ml-0">
      <div
        class="flex justify-end items-center text-xs sm:text-base"
        v-tooltip="getPreviousPeriodComparedPercentageTitle"
      >
        <span v-html="percentageIcon" v-if="value !== 0"></span>
        <span v-html="percentageString" class="ml-1"></span>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBarX from '@/components/analytics/charts/ProgressBarX'

export default {
  name: 'HorizontalBarChart',
  components: {
    ProgressBarX,
  },
  props: {
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    count: {
      type: Number,
    },
    value: {
      type: Number,
    },
    summaryPercentage: {
      type: Number,
    },
    isSummaryPercentagePositive: {
      type: Boolean,
      default: true,
    },
    compare: {
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    percentageIcon: function() {
      if (this.isSummaryPercentagePositive === true) {
        return '<i class="fas fa-arrow-up  text-oGreen"></i>'
      }
      return '<i class="fas fa-arrow-down text-oRed"></i>'
    },
    percentageString: function() {
      if (this.isSummaryPercentagePositive === true) {
        return `<div class=" p-positive">${this.summaryPercentage}%</div>`
      }
      return `<div class="p-negative">${this.summaryPercentage}%</div>`
    },
    getCountTitle() {
      if (this.type === 'iot-unlock') {
        return 'Total number of Failed Unlock Attempt'
      }
      if (this.type === 'iot-lock') {
        return 'Total number of Failed lock Attempt'
      } else return ''
    },
    getTotalRatePeriodPercentageTitle() {
      if (this.type === 'iot-unlock') {
        return 'The percentage of total failed unlock attempts'
      }
      if (this.type === 'iot-lock') {
        return 'The percentage of total failed lock attempts'
      } else return ''
    },
    getPreviousPeriodComparedPercentageTitle() {
      if (this.type === 'iot-unlock') {
        if (this.isSummaryPercentagePositive === true) {
          return `The percentage of failed unlock attempts has increased ${this.summaryPercentage}% compared to the previous period`
        }
        return `The percentage of failed unlock attempts has decreased ${this.summaryPercentage}% compared to the previous period`
      }
      if (this.type === 'iot-lock') {
        if (this.isSummaryPercentagePositive === true) {
          return `The percentage of failed lock attempts has increased ${this.summaryPercentage}% compared to the previous period`
        }
        return `The percentage of failed lock attempts has decreased ${this.summaryPercentage}% compared to the previous period`
      } else return ''
    },
  },
}
</script>

<style>
.icon-up,
.icon-down {
  height: 15px;
  width: 15px;
}
.icon-up,
.p-positive {
  color: #09aa35;
}
.icon-down,
.p-negative {
  color: #d90a20;
}
</style>
