<template>
  <base-layout class="pb-2 min-h-screen page-gradient">
    <section>
      <TheSuspense class="py-1 mb-2" v-if="!dropdownLoaded">
        <div class="flex justify-between items-center space-x-5">
          <SuspenseTab :total="1" class="mt-0" />

          <SuspenseTab :total="1" />
        </div>
      </TheSuspense>

      <div
        class="md:flex items-center mb-2 md:justify-between sm:justify-center title"
        v-if="dropdownLoaded"
      >
        <t-rich-select
          :options="getOrganization"
          valueAttribute="id"
          textAttribute="name"
          :placeholder="
            $store.getters['auth/userInfo'].is_superadmin
              ? 'All Organizations'
              : $t('components.homepage.myOrg')
          "
          v-model="currentOrganization"
          class="md:w-72 sm:w-100"
          variant="analyticsOrg"
          @change="onChangeOrg"
        />

        <div class="flex justify-end items-center">
          <div id="fleet-container">
            <!-- <FleetDropdown
              :fleetData="fleetsInfo"
              @getFleetChart="onChangeFleet"
            /> -->
          </div>
          <DashboardDatePicker
            class="hidden sm:flex"
            @apply-date="onChangeDate($event, 'line')"
            @cancel-date="handleCancelDate($event, 'line')"
          />
        </div>
      </div>

      <content-section class="px-0 mt-3">
        <!-- <div class="flex justify-center py-8 w-full md:hidden">
          <DashboardDatePicker
            @apply-date="onChangeDate($event, 'line')"
            @cancel-date="handleCancelDate($event, 'line')"
          />
        </div> -->

        <AreaChartCombo
          :org-id="orgId"
          :fleet-id="fleetId"
          :date-range="dateRange"
          :class="`mt-2`"
        />
      </content-section>
    </section>

    <section>
      <content-section class="mt-2 md:px-12 md:pb-10">
        <div class="flex flex-col justify-end items-center my-5 md:flex-row">
          <div class="mt-5 section-title md:mt-0 md-max:justify-center">
            <DashboardDatePicker
              @apply-date="onChangeDate($event, 'iot-for-unlock')"
              @cancel-date="handleCancelDate($event, 'iot-for-unlock')"
            />
          </div>
        </div>

        <template v-if="iotUnlockChartLoaded">
          <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <div class="section-title">Failed Unlock</div>
              <div>
                <div class="flex flex-wrap items-center mb-0 h-12 md-max:mt-8">
                  <div class="w-full">
                    <span class="uppercase section-title-faded">IOT Name</span>
                  </div>

                  <div class="h-px bg-gray-300" style="width: 88%;"></div>
                </div>
                <template
                  v-for="(item,
                  index) in unsuccessful_unlock_details.unsuccessful_unlock"
                >
                  <horizontal-bar-chart
                    :key="index"
                    type="iot-unlock"
                    :value="item.unsuccessful_rate_by_percentage"
                    :title="getCategoryName(item.iot_category)"
                    :count="item.unsuccessful_unlock_count"
                    :summary-percentage="item.compare_by_percentage"
                    :is-summary-percentage-positive="
                      item.compare_by_percentage < 0 ? false : true
                    "
                    class="my-2"
                  />
                </template>
              </div>
            </div>
            <div>
              <div class="section-title">
                Failed Unlock Reason
              </div>
              <div>
                <div class="flex flex-wrap items-center mb-0 h-12 md-max:mt-8">
                  <div class="w-full">
                    <span class="uppercase section-title-faded"
                      >REASON NAME</span
                    >
                  </div>

                  <div class="h-px bg-gray-300" style="width: 88%;"></div>
                </div>
                <template
                  v-for="(item,
                  index) in unsuccessful_unlock_details.unsuccessful_unlock_reasons"
                >
                  <horizontal-bar-chart
                    :key="index"
                    type="iot-unlock-reason"
                    :value="item.compare_by_percentage"
                    :title="getCategoryName(item.title)"
                    :subTitle="item.description"
                    :count="item.count"
                    :summary-percentage="item.compare_by_percentage"
                    :is-summary-percentage-positive="
                      item.compare_by_percentage < 0 ? false : true
                    "
                    class="my-2"
                  />
                </template>
              </div>
            </div>
          </div>
        </template>
        <TheSuspense class="p-4 bg-white" v-if="!iotUnlockChartLoaded">
          <SuspenseImg :height="`646px`" />
        </TheSuspense>
      </content-section>
    </section>

    <section>
      <content-section class="mt-2 md:px-12 md:pb-10">
        <div class="flex flex-col justify-end items-center my-5 md:flex-row">
          <div class="mt-5 section-title md:mt-0 md-max:justify-center">
            <DashboardDatePicker
              @apply-date="onChangeDate($event, 'iot-for-lock')"
              @cancel-date="handleCancelDate($event, 'iot-for-lock')"
            />
          </div>
        </div>

        <template v-if="iotLockChartLoaded">
          <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div>
              <div class="section-title">Failed Lock</div>
              <div>
                <div class="flex flex-wrap items-center mb-0 h-12 md-max:mt-8">
                  <div class="w-full">
                    <span class="uppercase section-title-faded">IOT Name</span>
                  </div>

                  <div class="h-px bg-gray-300" style="width: 88%;"></div>
                </div>
                <template
                  v-for="(item,
                  index) in unsuccessful_lock_details.unsuccessful_lock"
                >
                  <horizontal-bar-chart
                    :key="index"
                    type="iot-unlock"
                    :value="item.unsuccessful_rate_by_percentage"
                    :title="getCategoryName(item.iot_category)"
                    :count="item.unsuccessful_lock_count"
                    :summary-percentage="item.compare_by_percentage"
                    :is-summary-percentage-positive="
                      item.compare_by_percentage < 0 ? false : true
                    "
                    class="my-2"
                  />
                </template>
              </div>
            </div>
            <div>
              <div class="section-title">
                Failed Lock Reason
              </div>
              <div>
                <div class="flex flex-wrap items-center mb-0 h-12 md-max:mt-8">
                  <div class="w-full">
                    <span class="uppercase section-title-faded"
                      >REASON NAME</span
                    >
                  </div>

                  <div class="h-px bg-gray-300" style="width: 88%;"></div>
                </div>
                <template
                  v-for="(item,
                  index) in unsuccessful_lock_details.unsuccessful_lock_reasons"
                >
                  <horizontal-bar-chart
                    :key="index"
                    type="iot-unlock-reason"
                    :value="item.compare_by_percentage"
                    :title="getCategoryName(item.title)"
                    :subTitle="item.description"
                    :count="item.count"
                    :summary-percentage="item.compare_by_percentage"
                    :is-summary-percentage-positive="
                      item.compare_by_percentage < 0 ? false : true
                    "
                    class="my-2"
                  />
                </template>
              </div>
            </div>
          </div>
        </template>
        <TheSuspense class="p-4 bg-white" v-if="!iotLockChartLoaded">
          <SuspenseImg :height="`646px`" />
        </TheSuspense>
      </content-section>
    </section>
    <!-- <section>
      <div class="grid grid-cols-1 lg:grid-cols-2 md:gap-x-4">
        <content-section>
          <div class="grid grid-cols-1">
            <div
              class="flex flex-wrap justify-between items-center my-5 section-title md-max:justify-center"
            >
              <span class="md-max:mb-4">{{
                this.$t('components.homepage.fleetHealthFeedback')
              }}</span>
              <DashboardDatePicker
                @apply-date="onChangeDate($event, 'health')"
                @cancel-date="handleCancelDate($event, 'health')"
              />
            </div>

            <div v-if="gaugeDataLoaded">
              <gauge-chart :data="guage_chart" v-if="guage_chart != null" />
            </div>

            <TheSuspense class="p-4 bg-white" v-if="!gaugeDataLoaded">
              <SuspenseImg :height="`280px`" />
            </TheSuspense>
          </div>
        </content-section>

        <content-section>
          <div class="block">
            <div
              class="flex flex-wrap justify-between items-center my-5 section-title md-max:justify-center"
            >
              <span class="md-max:mb-4">{{
                this.$t('components.homepage.fleetConditions')
              }}</span>
              <DashboardDatePicker
                @apply-date="onChangeDate($event, 'condition')"
                @cancel-date="handleCancelDate($event, 'condition')"
              />
            </div>

            <div class="mt-4" v-if="fleetHealthDataLoaded">
              <horizontal-bar-chart-alt
                v-for="(item, index) in fleet_conditions"
                :key="index"
                :title="item.status"
                :count="item.total"
                :value="item.perspectivePercentage"
                :end_date="end_date"
                :start_date="start_date"
                :class="`my-2`"
                :color="`#3a9eba`"
              />
            </div>

            <TheSuspense class="p-4 bg-white" v-if="!fleetHealthDataLoaded">
              <SuspenseImg :height="`300px`" />
            </TheSuspense>
          </div>
        </content-section>
      </div>
    </section> -->
  </base-layout>
</template>

<script>
import dayjs from 'dayjs'
import { AnalyticsConfig } from '@/config/AnalyticsConfig'

import BaseLayout from '@/views/shared/BaseLayout'

// import MapChart from '@/components/analytics/MapChart'
// import GaugeChart from '@/components/analytics/GaugeChart'
import AreaChartCombo from '@/components/analytics/hardware/AreaChartCombo.vue'
import HorizontalBarChart from '@/components/analytics/hardware/HorizontalBarChart.vue'
// import HorizontalBarChartAlt from '@/components/analytics/HorizontalBarChartAlt'

// import FleetDropdown from '@/components/picker/FleetDropdown'
import ContentSection from '@/components/layout/ContentSection'
import DashboardDatePicker from '@/components/picker/date-range/DashboardDatePicker'
import { useEndpoints } from '@/composables'
export default {
  name: 'Home',
  components: {
    BaseLayout,

    // FleetDropdown,
    ContentSection,
    DashboardDatePicker,

    // MapChart,
    // GaugeChart,
    AreaChartCombo,
    HorizontalBarChart,
    // HorizontalBarChartAlt,
  },

  data() {
    return {
      req: {
        area: {
          busy: true,
        },
      },

      // filters
      orgId: this.$store.getters['auth/organizationInfo'].id || '',
      fleetId: '',
      // date range picker - used as a prop for AreaChartCombo
      dateRange: {
        start: dayjs()
          .subtract(6, 'day')
          .format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
      },

      isLoaded: false,
      isLoading: false,
      guage_chart: null,
      currentOrganization: null,
      currentFleet: null,

      // other
      dropdownLoaded: false,
      iotUnlockChartLoaded: false,
      iotLockChartLoaded: false,
      gaugeDataLoaded: false,
      fleetHealthDataLoaded: false,
      activityDataLoaded: false,

      fleet_conditions: [],

      unsuccessful_unlock_details: {
        unsuccessful_unlock: [],
        unsuccessful_unlock_reasons: [],
      },
      unsuccessful_lock_details: {
        unsuccessful_lock: [],
        unsuccessful_lock_reasons: [],
      },
      map_data: [],
      sorted_map_data: [],
      reduced_map_data: [],

      vehicles: [],
      health_feedback: [],

      start_date: '',
      end_date: '',

      getOrganization: [],
      orgPlaceholder: '',

      fleetsInfo: [],
    }
  },

  async mounted() {
    await this.$http
      .get(`/dashboard/fleets/?dropdown`)
      .then((res) => {
        this.fleetsInfo = res.data.data
      })
      .catch((err) => {
        console.warn(err)
      })

    await this.$http
      .get(`/dashboard/organizations/?dropdown`)
      .then((res) => {
        this.getOrganization = res.data.data
      })
      .catch((err) => {
        console.warn(err)
      })

    this.dropdownLoaded = true

    if (this.start_date == '') {
      this.start_date = this.getLast7Day()
    }

    if (this.end_date == '') {
      this.end_date = this.getToday()
    }

    var event = {
      start: this.start_date,
      end: this.end_date,
    }

    await this.getIOTDataForUnlock('', event)
    await this.getIOTDataForLock('', event)
    await this.getGaugeData(event)
    await this.getFleetData(event)
    await this.getActivityData(event)
  },
  methods: {
    onChangeOrg(orgId) {
      this.orgId = orgId
      this.getIOTDataForUnlock(orgId)
      this.getIOTDataForLock(orgId)
    },

    onChangeFleet(e) {
      this.fleetId = e
    },

    async onChangeDate(event, type) {
      if (type == 'line') {
        this.dateRange = event
      } else {
        this.start_date = event.start
        this.end_date = event.end

        if (type === 'iot-for-unlock') {
          await this.getIOTDataForUnlock('', event)
          return
        }
        if (type === 'iot-for-lock') {
          await this.getIOTDataForLock('', event)
          return
        }
        if (type === 'activity') {
          await this.getActivityData(event)
          return
        }
        if (type === 'health') {
          await this.getGaugeData(event)
          return
        }
        if (type === 'condition') {
          await this.getFleetData(event)
          return
        }
      }
    },

    // todo: rename to fetch_Type_Data()
    async getIOTDataForUnlock(orgId = '', event = {}) {
      this.iotUnlockChartLoaded = false

      let start_date
      let end_date
      if (event.start && event.end) {
        start_date = event.start
        end_date = event.end
      } else {
        start_date = this.start_date
        end_date = this.end_date
      }
      let query =
        '?start_date=' +
        start_date.split('T')[0] +
        '&end_date=' +
        end_date.split('T')[0] +
        '&organization=' +
        `${orgId ? orgId : this.$store.getters['auth/organizationInfo'].id}`

      const api = useEndpoints.analytics.iotUnlockDataByIotCategory() + query

      let resData = await this.$http
        .get(api)
        .then((res) => res.data)
        .catch((err) => console.log('err = ', err))

      if (!resData) {
        console.warn('noMapRes')
        return
      }

      this.unsuccessful_unlock_details = resData

      this.iotUnlockChartLoaded = true
    },
    async getIOTDataForLock(orgId = '', event = {}) {
      this.iotLockChartLoaded = false

      let start_date
      let end_date
      if (event.start && event.end) {
        start_date = event.start
        end_date = event.end
      } else {
        start_date = this.start_date
        end_date = this.end_date
      }
      let query =
        '?start_date=' +
        start_date.split('T')[0] +
        '&end_date=' +
        end_date.split('T')[0] +
        '&organization=' +
        `${orgId ? orgId : this.$store.getters['auth/organizationInfo'].id}`

      const api = useEndpoints.analytics.iotLockDataByIotCategory() + query

      let resData = await this.$http
        .get(api)
        .then((res) => res.data)
        .catch((err) => console.log('err = ', err))

      if (!resData) {
        console.warn('noMapRes')
        return
      }

      this.unsuccessful_lock_details = resData

      this.iotLockChartLoaded = true
    },
    getCategoryName(name) {
      if (name.split('_').length > 1) {
        return name.replaceAll('_', ' ')
      } else return name
    },
    async getGaugeData(event) {
      this.gaugeDataLoaded = false
      let start_date = event.start
      let end_date = event.end

      let query =
        '?start_date=' +
        start_date.split('T')[0] +
        '&end_date=' +
        end_date.split('T')[0]
      const api = AnalyticsConfig.api.gauge_chart + query
      let resData = await this.$http
        .get(api)
        .then((res) => res.data.data)
        .catch((err) => console.log('err = ', err))

      if (!resData) {
        console.warn('norRes')
        return
      }
      let detail = {
        good: resData.good,
        bad: resData.bad,
      }
      this.guage_chart = detail

      this.gaugeDataLoaded = true
    },

    async getFleetData(event) {
      this.fleetHealthDataLoaded = false

      let start_date = event.start
      let end_date = event.end

      let query =
        '?start_date=' +
        start_date.split('T')[0] +
        '&end_date=' +
        end_date.split('T')[0]

      const api = AnalyticsConfig.api.bar_chart + query

      let resData = await this.$http
        .get(api)
        .then((res) => res.data.data)
        .catch((err) => console.log('err = ', err))

      if (!resData) {
        console.warn('norRes')
        return
      }
      const transformedData = resData.sort((a, b) =>
        a.total > b.total ? -1 : a.total < b.total ? 1 : 0
      )
      const maxTotalValue = Math.max(
        ...transformedData.map((item) => item.total)
      )

      transformedData.forEach((item) => {
        const p =
          maxTotalValue > 0 ? Number((item.total * 100) / maxTotalValue) : 0
        item.perspectivePercentage = p
      })
      this.fleet_conditions = transformedData
      console.log('fleet_conditions = ', this.fleet_conditions)
      this.fleetHealthDataLoaded = true
    },

    async getActivityData(event) {
      this.activityDataLoaded = false
      let start_date = event.start
      let end_date = event.end

      let query =
        '?start_date=' +
        start_date.split('T')[0] +
        '&end_date=' +
        end_date.split('T')[0]

      let api = ''

      let step = '&start_hour=6&step_hour=2'
      api = AnalyticsConfig.api.activity_chart + query + step

      let resData = await this.$http
        .get(api)
        .then((res) => res.data.data)
        .catch((err) => console.log('err = ', err))

      if (!resData) {
        console.warn('norRes')
        return
      }

      this.vehicles = resData
      this.activityDataLoaded = true
    },

    // Helpers
    getToday() {
      const today = dayjs()
      return today.format(this.format)
    },

    getLast7Day() {
      const last7day = dayjs().subtract(6, 'day')
      return last7day.format(this.format)
    },
  },
}
</script>

<style lang="scss" scoped>
.page-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(176, 199, 237, 1) 0%,
    rgba(203, 217, 240, 0.7)
  );
}
.main-title {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.section-title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.section-title-faded {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9c9ca6;
}

.mc {
  max-width: 95%;
  margin: 0 auto;
}
.ph {
  min-height: 400px;
}
</style>

<style>
.bg-my-gray-200 {
  background-color: #eceff1;
}
.swal2-actions {
  @apply flex justify-end;
}
</style>
